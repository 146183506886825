<template>
  <el-table :data="mmplForm.mmpl_mdrl_list" border @selection-change="handleSelectionPart" class="el-table-two" ref="multipleTable">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="60" align="center">
      <template v-slot="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>
    <el-table-column label="材料编号">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mdrl_no'">
          <el-input show-word-limit v-model="mmplForm.mmpl_mdrl_list[scope.$index].mdrl_no" placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="材料名称">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mdrl_name'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mdrl_name" maxlength="255" show-word-limit placeholder="暂无">
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="材料类型">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mdrl_type'">
          <el-select size="small" v-model="mmplForm.mmpl_mdrl_list[scope.$index].mdrl_type" placeholder="请选择材料类型" clearable>
            <el-option v-for="item in mdrlType" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="用量">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mmpl_mdrl_consume'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mmpl_mdrl_consume" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="材料规格">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mdrl_spec'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mdrl_spec" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="库位">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mmpl_part_weight'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mmpl_part_weight" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="库存数">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mmpl_part_weight'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mmpl_part_weight" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="状态">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mmpl_mdrl_status'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mmpl_mdrl_status" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="单位">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mdrl_unit'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mdrl_unit" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="单价">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mdrl_price'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mdrl_price" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="金额">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'mmpl_mdrl_list.' + scope.$index + '.mmpl_mdrl_total'">
          <el-input v-model="mmplForm.mmpl_mdrl_list[scope.$index].mmpl_mdrl_total" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'MmplPartList',
  props: {
    mmplForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectionsList: [],
      mtrlTypeGroupOpt: [],
      flag: true,
      mdrlType: [
        { id: 1, label: '主材料' },
        { id: 2, label: '辅材料' },
        { id: 3, label: '其他' }
      ]
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    handleSelectionPart(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionPart', this.selectionsList);
    },
    clearSelection() {
      this.$refs.multipleTable.clearSelection();
    }
  }
};
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl {
  margin-left: 5px;
}
.vd_wid100 {
  width: 100%;
}
.el-input-group__prepend {
  font-size: 16px;
  color: #000000;
}
</style>
