import topic from '../topic';
export const mdtkAPI = {
  getMdtks: topic.mdtkTopic + '/getMdtks',
  getPushMdtks9001: topic.mdtkTopic + '/getPushMdtks9001',
  updateScanById: topic.mdtkTopic + '/updateScanById',
  destroyMdtkByIds: topic.mdtkTopic + '/destroyMdtkByIds',
  getAllMdtks: topic.mdtkTopic + '/getAllMdtks',
  editMdtkUseNum: topic.mdtkTopic + '/editMdtkUseNum',
  getMdtkMqcds: topic.mdtkTopic + '/getMdtkMqcds'
};
