<template>
  <div class="vg_wrapper vd_top" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="mmplForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('mmplForm')"
      />
      <el-button type="primary" size="small" class="vd_export" v-if="isShow" @click="buttonRefresh()">刷新 </el-button>
      <div class="vd_export1">
        <!--				<el-button type="success" plain size="small" v-if="isShow"
													 @click="importMmpl">同步样品
								</el-button>
								<el-button type="warning" size="small" :disabled="mmplForm.status === 0" v-if="isShow && openFlag"
													 @click="generatePrice()">
									<span v-if="mmplForm.quta_id === 0">生成样品报价单</span>
									<span v-else>跳转报价单</span>
								</el-button>-->
      </div>
    </div>
    <el-form ref="mmplForm" :model="mmplForm" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="样品图片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mmplForm.imge_id" :imgeUrl="mmplForm.imge_url" :imgeUrlO="mmplForm.imge_urlO" />
              <i v-else class="el-icon-plus avatar-uploader-icon" :class="{ vd_cussor: isShow }"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="非毛绒委托单号" prop="requ_no">
                <el-input v-model="mmplForm.requ_no" show-word-limit disabled placeholder="请填写非毛绒委托单号"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具材质编号" prop="mdnc_part_mtqt">
                <el-input v-model="mmplForm.mdnc_part_mtqt" show-word-limit disabled placeholder="请填写模具名称"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具编号" prop="mdnc_no">
                <el-input v-model="mmplForm.mdnc_no" show-word-limit placeholder="请填写模具编号" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具部件" prop="mdnc_part_name">
                <el-input v-model="mmplForm.mdnc_part_name" disabled show-word-limit placeholder="请填写模具部件"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="中文品名" prop="mdnc_name">
                <el-input v-model="mmplForm.mdnc_name" disabled show-word-limit placeholder="请填写中文品名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具类型" prop="mdnc_part_type">
                <el-input v-model="mmplForm.mdnc_part_type" disabled show-word-limit placeholder="请填写模具类型"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具数量" prop="mdnc_part_num">
                <el-input v-model="mmplForm.mdnc_part_num" disabled show-word-limit placeholder="请填写模具数量"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具属性" prop="mdnc_part_attr" required>
                <el-input v-model="mmplForm.mdnc_part_attr" disabled show-word-limit placeholder="请填写模具属性" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="开模时间" prop="mmpl_time">
                <el-date-picker v-model="mmplForm.mmpl_time" type="date" placeholder="选择开模时间" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模盘尺寸" prop="mdnc_part_spec">
                <el-input v-model="mmplForm.mdnc_part_spec" disabled show-word-limit placeholder="请填写模盘尺寸" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="计划完成日期" prop="mmpl_pldate">
                <el-date-picker v-model="mmplForm.mmpl_pldate" type="date" placeholder="选择计划完成日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="表面处理" prop="mdnc_part_skin">
                <el-input v-model="mmplForm.mdnc_part_skin" disabled show-word-limit placeholder="请填写模盘尺寸" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="实际完成日期" prop="mmpl_redate">
                <el-date-picker v-model="mmplForm.mmpl_redate" type="date" placeholder="选择实际完成日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="采购人员" prop="mdnc_stff_name">
                <el-input v-model="mmplForm.mdnc_stff_name" disabled show-word-limit placeholder="请填写采购人员" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="模具尺寸" prop="mmpl_measu">
                <el-input v-model="mmplForm.mmpl_measu" show-word-limit placeholder="请填写模盘尺寸" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具存放地" prop="mdnc_part_supp">
                <el-input v-model="mmplForm.mdnc_part_supp" disabled show-word-limit placeholder="请填写模具存放地" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="开模周期" prop="mmpl_period">
                <el-input v-model="mmplForm.mmpl_period" show-word-limit placeholder="请填写开模周期">
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具进度" prop="mmpl_rate">
                <el-input v-model="mmplForm.mmpl_rate" show-word-limit placeholder="请填写模具进度" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="准时率" prop="mmpl_punc">
                <el-input v-model="mmplForm.mmpl_punc" show-word-limit placeholder="请填写准时率" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="模具生产商" prop="mmpl_manu">
                <el-select filterable v-model="mmplForm.mdin_producer" size="small">
                  <el-option v-for="item in mouldProdList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="晚交天数" prop="mmpl_late_date">
                <el-input v-model="mmplForm.mmpl_late_date" show-word-limit placeholder="请填写晚交天数" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="晚交原因" prop="mmpl_late_remark">
                <el-input v-model="mmplForm.mmpl_late_remark" show-word-limit placeholder="请填写晚交原因" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="vd_buttom"></el-row>
      <el-tabs v-model="activeName" class="vd_eltab vd_buttom" :disabled="true">
        <el-tab-pane label="BOM清单" name="BOM清单">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="importMmplMdrl">导入</el-button>
            <el-button type="danger" plain size="small" @click="delPartList()">删除</el-button>
          </div>
          <MmplPartList :mmplForm="mmplForm" @handleSelectionPart="handleSelectionPart" ref="bomTable"></MmplPartList>
        </el-tab-pane>
      </el-tabs>
      <el-tabs v-model="processName" @tab-click="seChange">
        <el-tab-pane v-for="(item, index) in processNameList" :label="item" :name="item" :key="item">
          <el-row>
            <!--<el-col :md="24" class="vg_mb_8">-->
            <!--  <el-button type="success" plain size="small" :disabled="isShow" @click="item.childFormShow = false" v-if="item.childFormShow"-->
            <!--    >编辑-->
            <!--  </el-button>-->
            <!--  <div v-else>-->
            <!--    <el-button type="primary" plain size="small" :disabled="isShow" @click="saveChildInfo(index)">保存</el-button>-->
            <!--    <el-button type="primary" plain size="small" :disabled="isShow" @click="saveChildCancel(index)">取消</el-button>-->
            <!--  </div>-->
            <!--</el-col>-->
            <dynamic-form
              v-bind="$attrs"
              :split="split"
              :form-list="formList"
              :need-btn="false"
              ref="formList"
              :lableWidth="`140px`"
              :selectOptionStyle="`font-size:24px`"
            ></dynamic-form>
            <dynamic-form
              v-if="elapsedTimeFromShow"
              v-bind="$attrs"
              :split="split"
              :form-list="elapsedTimeFrom"
              :need-btn="false"
              ref="elapsedTimeFrom"
              :lableWidth="`140px`"
              :selectOptionStyle="`font-size:24px`"
            ></dynamic-form>
            <dynamic-form
              v-if="costFormShow"
              v-bind="$attrs"
              :split="split"
              :form-list="costForm"
              :need-btn="false"
              ref="costForm"
              :lableWidth="`140px`"
              :selectOptionStyle="`font-size:24px`"
            ></dynamic-form>
            <dynamic-form
              v-bind="$attrs"
              :form-list="remarkForm"
              :split="24"
              :need-btn="false"
              ref="remarkForm"
              :lableWidth="`140px`"
              :selectOptionStyle="`font-size:24px`"
            ></dynamic-form>
            <!--            <el-col :md="24">
              <el-form label-width="90px" :ref="`childForm${index}`" :model="item" :disabled="item.childFormShow">
                <el-col :md="8">
                  <el-form-item label="上机时间:" size="samll" prop="oper_time" :rules="[{ required: true }]">
                    <el-date-picker v-model="item.oper_time" type="datetime" class="vg_mb_8" placeholder="选择上机时间"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="下机时间:" size="samll" prop="getm_time" :rules="[{ required: true }]">
                    <el-date-picker v-model="item.getm_time" type="datetime" class="vg_mb_8" placeholder="选择下机时间"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="返工时间:" size="samll" prop="rewk_time" :rules="[{ required: true }]">
                    <el-date-picker v-model="item.rewk_time" type="datetime" class="vg_mb_8" placeholder="选择返工时间"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="备注:" size="samll">
                    <el-input v-model="item.murf_proc_remark" class="vg_mb_8" type="textarea" :row="5" placeholder="请输入备注"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :md="8">
                  <el-form-item label="制作人:" size="samll">
                    <el-input v-model="item.stff_name" class="vg_mb_8" placeholder="请输入制作人"></el-input>
                  </el-form-item>
                  <el-form-item label="岗位:" size="samll">
                    <el-input v-model="item.position" class="vg_mb_8" placeholder="请输入岗位"></el-input>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-col>-->
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="mmplForm.stff_name" />
        </el-col>
      </el-row>
    </el-form>
    <el-dialog v-if="mdrlFlag" title="导入BOM清单" :visible.sync="mdrlFlag" width="70%">
      <MmplMtrlImport
        @falseMtrl="this.mdrlFlag = false"
        @mdrlChanel="this.mdrlFlag = false"
        @mdrlConfirm="mdrlConfirm"
        ref="MqcdMtrlImport"
      />
    </el-dialog>
  </div>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import { mmplAPI } from '@api/modules/mmpl';
import { imgeAPI } from '@api/modules/imge';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import MmplPartList from '@/views/MouldManagement/MmplManage/TabChild/Componet/TabPanel/MmplPartList.vue';
import imgPopover from '@/views/component/imgPopover';
import MmplMtrlImport from '@/views/MouldManagement/MmplManage/TabChild/Componet/MmplMtrlImport.vue';
import { cloneDeep } from 'lodash';
import { muopAPI } from '@api/modules/muop';
import { dateToTimeStamp } from '@assets/js/dateUtils';
import DynamicForm from '@/components/DynamicForm.vue';
import { mupcAPI } from '@api/modules/mupc';

export default {
  name: 'mmplEditMain',
  components: {
    editHeader,
    inputUser,
    imgPopover,
    MmplPartList,
    MmplMtrlImport,
    DynamicForm
  },
  data() {
    return {
      costFormShow: false,
      elapsedTimeFromShow: false,
      split: 8,
      remarkForm: [
        { label: '备注', prop: 'proceRemark', itemType: 'input', model: '', index: 0, rows: 5, type: 'textarea', disabled: true }
      ],
      timeForm: [
        { label: '开始时间', prop: 'startTime', itemType: 'date', model: null, index: 0, disabled: true },
        { label: '结束时间', prop: 'endTime', itemType: 'date', model: null, index: 0, disabled: true }
      ],
      defaultFrom: [
        {
          label: '选择模板',
          prop: 'proceTemplate',
          itemType: 'select',
          options: [{ label: '板片A', value: '1' }],
          model: '1',
          index: 0,
          disabled: true
        }
      ],
      costForm: [{ label: '委外费用', prop: 'proceMoney', itemType: 'input', model: '', index: 0, inputAppend: '元', disabled: true }],
      machineFrom: [
        {
          label: '选择机器',
          prop: 'proceMachine',
          itemType: 'select',
          options: [{ label: '板片A', value: '1' }],
          model: '1',
          index: 0,
          disabled: true
        }
      ],
      placeTimeFrom: [{ label: '到位时间', prop: 'placeTime', itemType: 'date', model: null, index: 0, disabled: true }],
      elapsedTimeFrom: [
        {
          label: '总耗时',
          prop: 'sumTime',
          itemType: 'date',
          model: null,
          index: 0,
          formatTime: 'HH:mm:ss',
          disabled: true
        }
      ],
      formList: [],
      mmplForm: {
        mmpl_mdrl_list: []
      },
      activeName: 'BOM清单',
      isShow: true,
      btn: {},
      stffType: [],
      stffForm: {
        stff_name: '',
        cptt_name: '',
        dept_name: '',
        dept_team_name: '',
        stff_id: Number,
        cptt_id: Number,
        dept_id: Number,
        dept_team_id: Number
      },
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      show: false,
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      loadingFlag: true,
      settNum: '',
      mmplListDisable: true,
      partSelectChoose: [],
      partCopy: [],
      mouldProdList: [
        { id: 0, label: '模房' },
        { id: 1, label: '工厂' }
      ],
      mdrlFlag: false,
      processName: '设计',
      processNameList: [],
      processBackup: [],
      mdrlListBackup: []
    };
  },
  created() {},
  mounted() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = false;
      this.getMmplInfo();
    },
    /**
     * 启用编辑
     */
    openEdit() {
      this.isShow = false;
      this.showBom = false;
      this.$emit('isShow', this.isShow);
    },
    /**
     * 撤销编辑
     */
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.showBom = true;
        this.$emit('isShow', this.isShow);
        this.show = true;
        this.$message({ type: 'info', message: '已撤销编辑!' });
        this.initData();
      });
    },
    seChange() {
      switch (this.processName) {
        case '设计':
          this.split = 8;
          this.formList = cloneDeep(this.defaultFrom.concat(this.timeForm));
          this.costFormShow = false;
          this.elapsedTimeFromShow = true;
          break;
        case '编程':
          this.split = 8;
          this.formList = cloneDeep(this.defaultFrom.concat(this.timeForm));
          this.costFormShow = false;
          this.elapsedTimeFromShow = true;
          break;
        case '钢材到位':
          this.split = 12;
          this.formList = cloneDeep(this.defaultFrom.concat(this.placeTimeFrom));
          this.elapsedTimeFromShow = false;
          this.costFormShow = false;
          break;
        case '模架到位':
          this.split = 12;
          this.formList = cloneDeep(this.defaultFrom.concat(this.placeTimeFrom));
          this.elapsedTimeFromShow = false;
          this.costFormShow = false;
          break;
        case 'CNC':
          this.split = 6;
          this.formList = cloneDeep(this.defaultFrom.concat(this.machineFrom).concat(this.timeForm));
          this.costFormShow = true;
          this.elapsedTimeFromShow = false;
          break;
        case 'EDM':
          this.split = 6;
          this.formList = cloneDeep(this.defaultFrom.concat(this.machineFrom).concat(this.timeForm));
          this.costFormShow = true;
          this.elapsedTimeFromShow = false;
          break;
        case '线切割':
          this.split = 12;
          this.formList = cloneDeep(this.timeForm);
          this.costFormShow = true;
          this.elapsedTimeFromShow = true;
          break;
        case '车床':
          this.split = 12;
          this.formList = cloneDeep(this.timeForm);
          this.costFormShow = true;
          this.elapsedTimeFromShow = true;
          break;
        case '省模':
          this.split = 12;
          this.formList = cloneDeep(this.timeForm);
          this.costFormShow = true;
          this.elapsedTimeFromShow = true;
          break;
        case '装配':
          this.split = 12;
          this.formList = cloneDeep(this.timeForm);
          this.costFormShow = true;
          this.elapsedTimeFromShow = true;
          break;
        case '表面处理':
          this.formList = cloneDeep(this.timeForm);
          this.costFormShow = true;
          this.elapsedTimeFromShow = true;
          break;
        case '检验完成':
          this.split = 24;
          this.costFormShow = false;
          this.elapsedTimeFromShow = false;
          break;
        case '模具完成':
          this.split = 24;
          this.costFormShow = false;
          this.elapsedTimeFromShow = false;
          break;
      }
      get(mupcAPI.readMurfCard, { murf_proc_name: this.processName, murf_no: this.mmplForm.murf_no }).then(res => {
        if (res.data.code === 0) {
          this.processNameList = res.data.data.murf.proc_order.split(',');
          let formList = cloneDeep(this.formList);
          let map = formList.map(({ prop }) => prop);
          let handledArr = [];
          res.data.data.proc.forEach((item, index) => {
            let deepForm = cloneDeep(formList);
            map.forEach((subItem, subIndex) => {
              deepForm[subIndex].model = item[subItem];
              deepForm[subIndex].index = index;
              deepForm[subIndex].proceId = item.proceId;
            });
            handledArr = handledArr.concat(deepForm);
          });
          this.formList = handledArr;
          if (this.elapsedTimeFromShow) this.elapsedTimeFrom[0].model = res.data.data?.sumTime ?? 0;
          if (this.costFormShow) this.costForm[0].model = res.data.data?.proceMoney ?? 0;
        }
      });
    },
    /*
				//生成采购报价单
				generatePrice() {
					if (this.mmplForm.quta_id === 0) {
						if (!this.mmplForm.imge_id) {
							return this.$message.warning("请上传图片");
						}
						// if(!this.mmplForm.mmpl_box_l || Number(this.mmplForm.mmpl_box_l)===0){
						//   return this.$message.warning("请填写纸箱长度且纸箱长度不可为0");
						// }
						// if(!this.mmplForm.mmpl_box_w || Number(this.mmplForm.mmpl_box_w)===0){
						//   return this.$message.warning("请填写纸箱宽度且纸箱宽度不可为0");
						// }
						// if(!this.mmplForm.mmpl_box_h || Number(this.mmplForm.mmpl_box_h)===0){
						//   return this.$message.warning("请填写纸箱高度且纸箱高度不可为0");
						// }
						// if(!this.mmplForm.mmpl_bag_l || Number(this.mmplForm.mmpl_bag_l)===0){
						//   return this.$message.warning("请填写塑料袋长度且塑料袋长度不可为0");
						// }
						// if(!this.mmplForm.mmpl_bag_w || Number(this.mmplForm.mmpl_bag_w)===0){
						//   return this.$message.warning("请填写塑料袋宽度且塑料袋宽度不可为0");
						// }
						// if(!this.mmplForm.mmpl_qpc || Number(this.mmplForm.mmpl_qpc)===0){
						//   return this.$message.warning("请填写装箱数且装箱数不可为0");
						// }
						// if(!this.mmplForm.mmpl_qpb || Number(this.mmplForm.mmpl_qpb)===0){
						//   return this.$message.warning("请填写装袋数且装袋数不可为0");
						// }
						// if(!this.mmplForm.mmpl_moq || Number(this.mmplForm.mmpl_moq)===0){
						//   return this.$message.warning("请填写最小起订量且最小起订量不可为0");
						// }
						// if(!this.mmplForm.mmpl_skin_weight || Number(this.mmplForm.mmpl_skin_weight)===0){
						//   return this.$message.warning("请填写皮壳重量且皮壳重量不可为0");
						// }
						// if(!this.mmplForm.mmpl_fill_weight || Number(this.mmplForm.mmpl_fill_weight)===0){
						//   return this.$message.warning("请填写填充物重量且填充物重量不可为0");
						// }
						let params = {}
						let userInfo = this.$cookies.get("userInfo");
						params.mmpl_id = this.mmplForm.mmpl_id;
						params.user_id = userInfo.user_id;
						params.stff_id = userInfo.stff_id;
						params.dept_id = userInfo.dept_id;
						params.dept_team_id = userInfo.dept_team_id;
						params.stff_name = userInfo.stff_name;
						post(qutaAPI.addQuta, params)
								.then(res => {
									if (res.data.code === 0) {
										this.$message({
											type: "success",
											message: "生成成功",
										});
										this.initData()
										this.jump('/quta_edit', {
													perm_id: 123,
													form_id: res.data.data.form_id
												}
										)
									}
								})
					} else {
						this.jump('/quta_edit', {
									perm_id: 123,
									form_id: this.mmplForm.quta_id
								}
						)
					}
				},*/
    /**
     * 提交表单
     */
    submit(formName) {
      let constr;
      if (this.$route.query.rework === 'true') {
        constr = '是否确认保存？保存之后单据将重新变为草拟状态';
      } else {
        constr = '确认提交?';
      }
      this.$confirm(constr, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.saveInfo();
          } else {
            console.log('error submit!');
            return false;
          }
        });
      });
    },
    /**
     * 保存
     */
    saveInfo() {
      this.mdrlListBackup = this.mdrlListBackup.filter(fitem =>
        this.mmplForm.mmpl_mdrl_list.every(eitem => eitem.mdtk_id !== fitem.mdtk_id)
      );
      this.mdrlListBackup.forEach(item => (item.destroy_flag = 1));
      this.mmplForm.mmpl_mdrl_list = this.mmplForm.mmpl_mdrl_list.concat(this.mdrlListBackup);
      postNoCatch(mmplAPI.editMmpl, this.mmplForm).then(({ data }) => {
        this.$message({ type: 'success', message: '保存成功' });
        this.isShow = true;
        this.initData();
      });
    },
    /**
     * 刷新按钮
     */
    buttonRefresh() {
      this.initData();
    },
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mmplForm.imge_id = res.data.imge_id;
        this.mmplForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.mmplForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      }
    },
    /**
     * 上传头像前
     */
    beforeAvatarUpload() {},
    /**
     * 打开导入BOM清单弹窗
     */
    importMmplMdrl() {
      this.mdrlFlag = true;
    },
    /**
     * 表单重置
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**
     * 获取表单信息
     */
    getMmplInfo() {
      getNoCatch(mmplAPI.getMmplById, { mmpl_id: this.$route.query.form_id }).then(({ data }) => {
        for (const dataKey in data.form) {
          if (data.form[dataKey] === 0) {
            data.form[dataKey] = null;
          }
        }
        this.mmplForm = data.form;
        this.seChange();
        // this.processBackup = cloneDeep(data.form.murf_proc_list);
        // this.mdrlListBackup = cloneDeep(data.form.mmpl_mdrl_list);
        this.btn = data.btn;
        this.getInputUser();
      });
    },
    /**
     * 获取录入人
     */
    getInputUser() {
      this.stffForm.cptt_id = this.mmplForm.cptt_id;
      this.stffForm.user_id = this.mmplForm.user_id;
      this.stffForm.dept_id = this.mmplForm.dept_id;
      this.stffForm.stff_id = this.mmplForm.stff_id;
      this.stffForm.dept_name = this.mmplForm.dept_name;
      this.stffForm.dept_team_id = this.mmplForm.dept_team_id;
      this.stffForm.dept_team_name = this.mmplForm.dept_team_name;
      this.stffForm.stff_name = this.mmplForm.stff_name;
    },
    /**
     * 材料导入确认
     * @param val 弹窗选中的对象
     */
    mdrlConfirm(val) {
      this.mdrlFlag = false;
      this.mmplForm.mmpl_mdrl_list = this.mmplForm.mmpl_mdrl_list.concat(val);
      this.mmplForm.mmpl_mdrl_list.sort((x, y) => x.mdrl_type - y.mdrl_type);
    },
    /**
     * 保存工序信息
     * @param index 工序下标
     */
    saveChildInfo(index) {
      this.$refs[`childForm${index}`][0].validate(valid => {
        if (valid) {
          let tempForm = cloneDeep(this.mmplForm.murf_proc_list[index]);
          tempForm.rewk_time = dateToTimeStamp(tempForm.rewk_time);
          tempForm.getm_time = dateToTimeStamp(tempForm.getm_time);
          tempForm.oper_time = dateToTimeStamp(tempForm.oper_time);
          let { user_id, stff_id, stff_name } = this.$cookies.get('userInfo');
          tempForm.user_id = user_id;
          tempForm.stff_id = stff_id;
          tempForm.stff_name = stff_name;
          postNoCatch(muopAPI.editMurfProc, tempForm).then(({ data, code, msg }) => {
            if (code !== 0) {
              this.saveChildCancel(index);
              return this.$message.success(msg);
            }
            this.mmplForm.murf_proc_list[index].childFormShow = true;
            this.$message.success('提交成功!');
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 保存取消 恢复元数据 刷新视图
     * @param {string|number} index 工序下标
     */
    saveChildCancel(index) {
      this.$set(this.mmplForm.murf_proc_list[index], 'oper_time', this.processBackup[index].oper_time);
      this.$set(this.mmplForm.murf_proc_list[index], 'rewk_time', this.processBackup[index].rewk_time);
      this.$set(this.mmplForm.murf_proc_list[index], 'getm_time', this.processBackup[index].getm_time);
      this.$set(this.mmplForm.murf_proc_list[index], 'murf_proc_remark', this.processBackup[index].murf_proc_remark);
      this.$set(this.mmplForm.murf_proc_list[index], 'childFormShow', this.processBackup[index].childFormShow);
    },
    /**
     * 配件删除
     */
    delPartList() {
      if (this.partSelectChoose.length === 0) return this.$message.warning('至少选择一条数据');
      this.partSelectChoose.forEach(item => {
        let index = this.mmplForm.mmpl_mdrl_list.findIndex(x => x.mmpl_mdrl_id === item.mmpl_mdrl_id);
        this.mmplForm.mmpl_mdrl_list.splice(index, 1);
      });
      this.$refs.bomTable.clearSelection();
    },
    /**
     * BOM清单勾选框
     */
    handleSelectionPart(val) {
      this.partSelectChoose = val;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_eltab {
  margin-top: 10px;
}

.vd_buttom {
  border-bottom: 1px dashed #c0c4cc;
  padding-bottom: 32px;
}

.vd_but {
  margin-bottom: 10px;
}

.vd_ml10 {
  margin-left: 10px;
}

.vd_dis {
  display: inline-block;
}

.vd_mrl {
  margin-left: 5px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
  margin-bottom: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export1 {
  position: absolute;
  top: 0;
  left: 130px;
}

.vd_export2 {
  position: absolute;
  top: 0;
  left: 185px;
  background-color: rgb(230, 215, 7) !important;
  border: 1px solid rgb(230, 215, 7) !important;
}

.vd_export2:hover {
  background-color: rgb(232, 222, 93) !important;
  border: 1px solid rgb(232, 222, 93) !important;
}

.vd_exports {
  position: absolute;
  top: 0;
  left: 120px;
}

.vd_mrb {
  margin-bottom: 1px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_cussor {
  cursor: default;
}
</style>
